import React, { Component } from "react";
import {
  initWeb3,
  getMedianizer,
  sendMessage,
  estimateFee,
  DB_ADDRESS,
  ABOUT_ADDRESS,
  ETHERSCAN_URL,
  CHAIN_NAME
} from "utils/web3";
import { utils, ethers } from "ethers";
import { convertWeiToDollars } from "utils/currency";
import { format } from "date-fns";
import { Link } from "react-router-dom";
import marked from "marked";
import TextareaAutosize from "react-textarea-autosize";
import {
  clearDraftMessage,
  fetchDraftMessage,
  saveDraftMessageDebounced
} from "utils/local";
import { DIVIDER } from "utils/style";

import "tachyons";
import "./App.css";

const DEFAULT_MESSAGE = "# Etch me into the blockchain";
const TX_BLACKLIST = [
  "0x1d1b84b9e297b6ef889ce0e25009889d3932bf2bc98997e43eaef4d94f415d53",
  "0xc07e14d2a0c76520b4f3ae0e87012ea6fe0eaab6550e55b802bbe42570838597"
];

class App extends Component {
  state = {
    storageValue: 0,
    web3: null,
    accounts: null,
    contract: null
  };

  textarea = null;

  componentDidMount = async () => {
    const messageSrc = (await fetchDraftMessage()) || DEFAULT_MESSAGE;
    this.setState({
      compiledMarkdown: marked(messageSrc, { sanitize: true }),
      defaultMessage: messageSrc
    });

    this.setTimestamp();
    this.setEstimate(messageSrc);
    this.loadHistory();
    this.timestampInterval = setInterval(this.setTimestamp, 1000);
  };

  componentWillUnmount() {
    this.setState({ defaultMessage: null, compiledMarkdown: null });
    clearInterval(this.timestampInterval);
  }

  onSubmit = async () => {
    if (!this.textarea) return;
    const message = this.textarea.value;
    if (!message) return;

    try {
      this.setState({ pendingMessage: true });
      await initWeb3();
      const tx = await sendMessage(message);
      clearDraftMessage();
      this.props.history.push(`/tx/${tx.hash}`);
    } catch (err) {
      console.log(err);
      this.setState({ pendingMessage: false });
    }
  };

  loadHistory = async () => {
    let etherscanProvider = new ethers.providers.EtherscanProvider(CHAIN_NAME);

    etherscanProvider.getHistory(DB_ADDRESS).then(history => {
      history.reverse();
      this.setState({
        history: history.filter(tx => !TX_BLACKLIST.includes(tx.hash))
      });
    });
  };

  onEditorChange = el => {
    const message = el.target.value;

    this.setState({
      compiledMarkdown: marked(message, { sanitize: true })
    });

    saveDraftMessageDebounced(message);
    this.setEstimate(message);
  };

  setTimestamp = () => {
    const timestamp = format(new Date(), "D MMMM YYYY h:mm:ss a");

    if (this.state.timestamp !== timestamp) {
      this.setState({ timestamp });
    }
  };

  setEstimate = async message => {
    if (!this.exchangeRate) {
      const medianizer = await getMedianizer();
      const exchangeRate = utils.bigNumberify((await medianizer.compute())[0]);
      this.exchangeRate = exchangeRate.toString();
    }

    estimateFee(message).then(estimate => {
      this.setState({
        feeDollars: convertWeiToDollars(estimate, this.exchangeRate),
        feeWei: estimate.toString()
      });
    });
  };

  setTextarea = el => {
    if (el && !this.textarea) {
      el.focus();
    }
    this.textarea = el;
  };

  render() {
    return (
      <div className="editor-container">
        <div className="editor">
          <div className="editor-code ph5">
            <p className="title pt4 mt0 mb5">
              Permadoc{" "}
              <Link className="f3" to={`/tx/${ABOUT_ADDRESS}`}>
                ?
              </Link>
            </p>
            {typeof this.state.defaultMessage === "string" && (
              <TextareaAutosize
                key={this.state.defaultMessage}
                inputRef={this.setTextarea}
                defaultValue={this.state.defaultMessage}
                className="editor-code-textarea f4 ph0"
                onChange={this.onEditorChange}
                placeholder="Type a message"
                onScroll={this.onEditorScroll}
              />
            )}
          </div>
          <div className="editor-compiled f6 pa5">
            <div className="preview message pv5 mb4">
              <span className="f6 editor-timestamp">
                {this.state.timestamp}
              </span>
              <div
                dangerouslySetInnerHTML={{
                  __html: this.state.compiledMarkdown
                }}
              />
            </div>
            {this.state.history &&
              this.state.history.map(tx => (
                <div key={tx.hash} className="message pv5">
                  <a className="f6 timestamp" href={`/tx/${tx.hash}`}>
                    {format(tx.timestamp * 1000, "D MMMM YYYY h:mm:ss a")}
                  </a>
                  {DIVIDER}
                  <a
                    className="f6 timestamp"
                    href={`${ETHERSCAN_URL}/tx/${tx.hash}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Etherscan
                  </a>
                  <div
                    className="f4 pt3"
                    dangerouslySetInnerHTML={{
                      __html: marked(utils.toUtf8String(tx.data), {
                        sanitize: true
                      })
                    }}
                  ></div>
                </div>
              ))}
          </div>
        </div>

        <div className="deploy-button-container fixed">
          <button
            className="underline-hover pointer deploy-button f4 link ph5 pv3 mv5 dib white fixed bottom-1"
            onClick={this.onSubmit}
          >
            {this.state.pendingMessage ? (
              "Deploying..."
            ) : (
              <>
                Deploy&nbsp;&nbsp;&rarr;
                <span className="pt1 db f6 est">
                  Est. cost:{" "}
                  {this.state.feeWei
                    ? `${this.state.feeDollars} (${this.state.feeWei} wei)`
                    : "--"}
                </span>
              </>
            )}
          </button>
        </div>
      </div>
    );
  }
}

export default App;
