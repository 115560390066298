import React, { Component } from "react";
import marked from "marked";
import { ethers, utils } from "ethers";
import { format } from "date-fns";
import { saveDraftMessage } from "utils/local";
import { CHAIN_NAME, ETHERSCAN_URL, ABOUT_ADDRESS } from "utils/web3";
import { DIVIDER } from "utils/style";
import { Link } from "react-router-dom";

import "./Transaction.css";

// Detail view for a transaction.
class Transaction extends Component {
  state = { compiledMarkdown: "" };

  componentDidMount = async () => {
    const { hash } = this.props.match.params;
    document.title =
      hash === ABOUT_ADDRESS
        ? `Permadoc${DIVIDER}Readme`
        : `Permadoc${DIVIDER}${hash}`;

    const provider = ethers.getDefaultProvider(CHAIN_NAME);
    const tx = await provider.getTransaction(hash);
    if (!tx) {
      this.setState({ notFound: true });
      return;
    }
    const block = await provider.getBlock(tx.blockHash);
    const messageSrc = utils.toUtf8String(tx.data);
    this.setState({
      compiledMarkdown: marked(messageSrc, { sanitize: true }),
      timestamp: format(block.timestamp * 1000, "D MMMM YYYY h:mm a"),
      tx,
      messageSrc
    });
  };

  onCodeChange = el => {
    this.props.onChange(el.target.value);
    this.setState({
      compiledMarkdown: marked(el.target.value, { sanitize: true })
    });
  };

  onForkPressed = async () => {
    this.setState({ forkLoading: true });
    await saveDraftMessage(this.state.messageSrc);
    this.props.history.push("/");
  };

  render() {
    const { compiledMarkdown, timestamp, tx, notFound } = this.state;
    if (notFound) {
      return (
        <h1 className="ph2 ph5-ns">
          <Link to="/">
            Permadoc
          </Link>
          {DIVIDER}Not found
        </h1>
      );
    }
    if (!tx) {
      return <h1 className="ph2 ph5-ns">...</h1>;
    }
    return (
      <div className="editor ph2 ph5-ns">
        <div key={tx.hash} className="message pv5">
          <Link className="f6 timestamp" to="/">
            Permadoc
          </Link>
          {DIVIDER}
          <span className="f6 timestamp">{timestamp}</span>
          {DIVIDER}
          <a
            className="f6 timestamp"
            href={`${ETHERSCAN_URL}/tx/${tx.hash}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            Etherscan
          </a>
          {DIVIDER}
          <button className="f6 timestamp" onClick={this.onForkPressed}>
            {this.state.forkLoading ? "..." : "Fork"}
          </button>

          <div
            className="f3 pt3"
            dangerouslySetInnerHTML={{
              __html: compiledMarkdown
            }}
          ></div>
        </div>
      </div>
    );
  }
}

export default Transaction;
