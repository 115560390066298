import { ethers, utils } from "ethers";

const medianizerAbi = require("../contracts/Medianizer.json");

let medianizer, eth;

// Rinkeby settings
// const CHAIN_ID = 4;
// export const CHAIN_NAME = "rinkeby";
// export const ETHERSCAN_URL = "https://rinkeby.etherscan.io"
// export const ABOUT_ADDRESS =
//   "0x7151a8a801e912f382e03500da149a01ad323c3579ed3e4d94e2ec880a35ecc4";

// Prod settings
const CHAIN_ID = 1;
export const CHAIN_NAME = "homestead";
export const ETHERSCAN_URL = "https://etherscan.io";
export const ABOUT_ADDRESS =
  "0x6b89abaec26caf4d508530c8391704838b6f58e8bfa43db04121c5c162e3b72b";

export const DB_ADDRESS = "0x9e91139e764cF44d1d53EbeF3857495cE6561F89";
export const MEDIANIZER_ADDRESS = "0x729D19f657BD0614b4985Cf1D82531c67569197B";

export async function initWeb3() {
  await initMetamask();
}

async function checkNetwork(eth) {
  const network = await eth.getNetwork();
  console.log("network: ", network);
  if (network.chainId !== CHAIN_ID) {
    alert("Please set Web3 provider to Rinkeby and try again.");
    return false;
  }
  return true;
}

export async function initMetamask() {
  if (!window.ethereum && !window.web3) {
    // Non-DApp browsers won't work.
    alert("Web3 not detected. Try Metamask");
  }
  let coinbase = "";
  if (window.ethereum) {
    // Modern DApp browsers need to enable Metamask access.
    await window.ethereum.enable();
    let web3Provider = window["ethereum"] || window.web3.currentProvider;
    eth = new ethers.providers.Web3Provider(web3Provider);
    if (await checkNetwork(eth)) {
      localStorage.setItem("loginType", "metamask");
      coinbase = (await eth.listAccounts())[0].toLowerCase();
    }
  }
  window.localStorage.setItem("loggedInUser", coinbase);
  return eth;
}

export async function initMedianizer() {
  // always pull from mainnet
  let provider = ethers.getDefaultProvider();
  medianizer = new ethers.Contract(MEDIANIZER_ADDRESS, medianizerAbi, provider);
  return medianizer;
}

export async function getMedianizer() {
  if (!medianizer) {
    await initMedianizer();
  }
  return medianizer;
}

export async function getEthSigner() {
  if (!eth) {
    if (localStorage.getItem("loginType") === "metamask") {
      eth = await initMetamask();
      // } else if (localStorage.getItem("loginType") === "gnosis") {
      //   eth = await initGnosisSafe()
    } else {
      throw new Error("Not logged in with web3.");
    }
  }
  return eth;
}

// Messages

function messageTransaction(message) {
  const data = utils.hexlify(utils.toUtf8Bytes(message));
  return {
    to: DB_ADDRESS,
    gasPrice: utils.bigNumberify("20000000000"),
    data: data,
    value: 0
  };
}

export async function estimateFee(message) {
  const provider = ethers.getDefaultProvider();
  const [gasEstimate, gasPrice] = await Promise.all([
    provider.estimateGas(messageTransaction(message)),
    provider.getGasPrice()
  ]);
  return gasEstimate.mul(gasPrice);
}

export async function sendMessage(message) {
  const provider = await getEthSigner();
  if (!provider) {
    return null;
  }

  const signer = provider.getSigner();
  const transaction = messageTransaction(message);
  const [gasEstimate, gasPrice] = await Promise.all([
    provider.estimateGas(messageTransaction(message)),
    provider.getGasPrice()
  ]);
  transaction.gasLimit = gasEstimate;
  transaction.gasPrice = gasPrice;
  return await signer.sendTransaction(transaction);
}
