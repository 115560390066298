import React, { Component } from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import Transaction from "./Transaction/Transaction";
import App from "./App";

class AppRouter extends Component {
  render() {
    return (
      <Router>
        <Route path="/" exact component={App} />
        <Route path="/tx/:hash" component={Transaction} />
      </Router>
    );
  }
}

export default AppRouter;
