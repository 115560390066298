import { debounce } from "lodash";
import localforage from "localforage";

const DRAFT_KEY = "draftMessage";
const PENDING_KEY = "pendingMessages";

/**
 * Draft messages have not been submitted.
 **/
 
export async function fetchDraftMessage() {
  return await localforage.getItem(DRAFT_KEY);
}

export async function saveDraftMessage(message) {
  await localforage.setItem(DRAFT_KEY, message);
}
export const saveDraftMessageDebounced = debounce(saveDraftMessage, 1000);

export async function clearDraftMessage() {
  localforage.removeItem(DRAFT_KEY);
}

/**
 * Pending messages have been submitted but not confirmed.
 * { address: string, timestamp: number }
 **/

export async function fetchPendingMessages() {
  return await localforage.getItem(PENDING_KEY);
}

export async function savePendingMessage(message) {
  await localforage.setItem(PENDING_KEY, message);
}

export async function clearPendingMessage() {
  localforage.removeItem(PENDING_KEY);
}

// We remove them after 10 minutes if they were still not removed manually.
export async function clearExpiredMessages() {  
  localforage.removeItem(PENDING_KEY);
}

